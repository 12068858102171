<template>
  <div class="form container">
    <div class="window">
      <div class="account__container">
        <div v-if="isFetching">
          Confirming...
          <br/>
          <b-spinner variant="dark"></b-spinner>
        </div>

        <div v-else>
          <div v-if="!fetchingError">
            Confirmed
          </div>

          <div class="text-danger" v-else>
            {{fetchingError}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {CONFIRM_EMAIL} from "@/store/modules/auth";
  import * as types from "@/store/mutations/types";

  export default {
    name: 'ConfirmAccount',
    data() {
      return {
        isFetching: true,
        fetchingError: null,
      }
    },
    mounted() {
      console.log('router', this.$router, this.$route, this.$store)
      this.confirmAccount()
    },
    methods: {
      async confirmAccount() {
        console.log('token', this.$route.query.token)
          // this.$bvToast.show('welcome-message')
        try {
          const h = this.$createElement

          const vNodeMsg = h(
            'div',
            {},
            [
              h('span', { class: 'welcome-message__icon' },
                [h('img', { src: '@/assets/images/check_green.svg', alt: 'check'})]
              ),
              'You are successfully logged in'
            ]
          );

          await this.$store.dispatch(CONFIRM_EMAIL, {
            token: this.$route.query.token,
            message: vNodeMsg,
          })

        } catch (e) {
          this.fetchingError = e.response && e.response.data && e.response.data.message || e.message
          console.error(e)
          console.dir(e)
          this.isFetching = false
        }
      }
    }
  }
</script>
